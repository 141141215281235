import React, { useEffect, useState } from "react";
import './Layout.scss';
import Page from "../../components/StructureElement/Page/Page";
import { useParams } from "react-router-dom";
import Article from "../../components/BlockElement/Article/Article";
import Hero from "../../components/Hero/Hero";
import { useSite } from "../../hooks/useSite";

export default function Layout(){
    const { site } = useParams();
    const { sites, fetchSite } = useSite(); 
    const [text, setText] = useState<string>('');

    useEffect(() => {
        (async () => {
          await fetchSite();   // fetchSite presumably sets "sites" in your global state or context
        })();
    }, [fetchSite]);

    useEffect(() => {
        if (!sites || sites.length === 0 || !site) return;
    
        const found = sites.find((s) => s.slug === site);
        if (found) {
          setText(found.html);
        } else {
          // optionally, handle not found
          setText("");
        }
    }, [sites, site]);


    return(
        <Page>
            <Hero />
            <Article><div dangerouslySetInnerHTML={{ __html: text }} /></Article>
        </Page>
    );
}