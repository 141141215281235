import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Nav from "../../BlockElement/Nav/Nav";
import { Button, Drawer, Menu } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import { useSite } from "../../../hooks/useSite";

export default function Navbar({ id }: IStructureElement) {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const { sites, fetchSite } = useSite(); 
    
    const init = async () => {
        await fetchSite();
    }

    useEffect(() => { init(); }, []);

    useEffect(() => {
        const handleScroll = () => {
          setIsScrolled(window.scrollY > 20)
        }
    
        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    };

    const navigation = sites.map(element => { return { name: element.name, href: "/" + element.slug }; });

    return (
        <nav className={`navbar ${isScrolled ? "navbar--scrolled" : ""}`}>
          <div className="navbar__container">
            <a href="/" className="navbar__logo">
              <span className="navbar__logo-main">Parafia Wniebowzięcia</span>
              <span className="navbar__logo-sub">Najświętszej Maryi Panny w Rogowie</span>
            </a>
    
            {/* Desktop Menu */}
            <ul className="navbar__menu">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a href={item.href} className="navbar__menu-item">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
    
            {/* Mobile Menu Button */}
            <button className="navbar__mobile-button" onClick={toggleMobileMenu} aria-label="Menu">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="3" y1="12" x2="21" y2="12" />
                <line x1="3" y1="6" x2="21" y2="6" />
                <line x1="3" y1="18" x2="21" y2="18" />
              </svg>
            </button>
    
            {/* Mobile Menu */}
            <div className={`navbar__mobile-menu ${isMobileMenuOpen ? "navbar__mobile-menu--open" : ""}`}>
              <button className="navbar__mobile-menu-close" onClick={toggleMobileMenu} aria-label="Close menu">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
              <a href="/" className="navbar__logo" style={{ marginBottom: "1rem", display: "block" }}>
                <span className="navbar__logo-main">Parafia Wniebowzięcia</span>
                <span className="navbar__logo-sub">Najświętszej Maryi Panny w Rogowie</span>
              </a>
              <ul className="navbar__mobile-menu-list">
                {navigation.map((item) => (
                  <li key={item.name} className="navbar__mobile-menu-item">
                    <a href={item.href} className="navbar__mobile-menu-link" onClick={toggleMobileMenu}>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
    );

    // return(
    //     <Nav id={id} className='navbar'>
    //         <Button className="navbar__button" icon={<MenuOutlined />} onClick={showSites} />
    //         <Drawer title="Menu" placement="right" onClose={hideSites} open={visible}>
    //             <Menu>{mapSite()}</Menu>
    //         </Drawer>
    //     </Nav>
    // );
}