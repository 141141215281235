import { Button, List, Upload } from "antd";
import Article from "../BlockElement/Article/Article";
import Section from "../BlockElement/Section/Section";
import Heading from "../TextElement/Heading/Heading";
import './Text.scss';
import Container from "../BlockElement/Container/Container";
import { IText } from "../../types/IText";
import TextArea from "antd/es/input/TextArea";
import { url } from "../../utils/urls";
import Paragraph from "../TextElement/Paragraph/Paragraph";
import { CloudUploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";

export default function Text({ site, ops, prev, sites, updateSite }: IText) {
    const [text, setText] = useState<string>('');

    useEffect(() => {
        const result = sites.find(value => value.slug === site);
        setText(result.html);
    }, []);


    switch(ops){
        case '1':
            return(
                <Article className='text--delete'>
                    {/* <Section className='text__section'>
                        <Heading type='h2'>Wprowadź tekst</Heading>
                        <TextArea value={text} onChange={(e) => setText(e.target.value)} style={{height: '20dvh', background: '#f9f9f9'}}/>
                        <Container>
                            <Button htmlType='button' style={{ marginTop: 10 }} onClick={() => prev()}>Cofnij</Button>
                            <Button htmlType='button' type="primary" style={{ marginTop: 10, marginLeft: 20 }} onClick={() => addText()}>Dodaj</Button>
                        </Container>
                    </Section> */}
                    {/* <Section className='text__section'> */}
                        <Heading type='h2'>Prześlij plik</Heading>
                        <Upload.Dragger
                            name="files"
                            action={`${url}/api/admin/site/${site}/upload`}  
                            headers={{ Authorization: `Bearer ${sessionStorage.getItem('jwt')}` }}                
                            className='text__upload'
                        >
                            <CloudUploadOutlined className='text__icon'/>
                            <Paragraph className="ant-upload-hint">Click or drag file to this area to upload</Paragraph>
                        </Upload.Dragger>
                        <Container>
                            <Button htmlType='button' style={{ marginTop: 50 }} onClick={() => prev()}>Cofnij</Button>
                        </Container>
                    {/* </Section> */}
                </Article>
            );
        case '2':
            return(
                <Article className='text'>
                    <Section className='text__section'>
                        <Heading type='h2'>Wprowadź tekst</Heading>
                        <TextArea value={text} onChange={(e) => setText(e.target.value)} style={{height: '20dvh', background: '#f9f9f9'}}/>
                        <Container>
                            <Button htmlType='button' style={{ marginTop: 10 }} onClick={() => prev()}>Cofnij</Button>
                            <Button htmlType='button' type="primary" style={{ marginTop: 10, marginLeft: 20 }} onClick={() => updateSite(site, text)}>Zapisz</Button>
                        </Container>
                    </Section>
                    <Section className='text__section'>
                        <div dangerouslySetInnerHTML={{ __html: text }} style={{width: "100%", maxHeight: '35dvh', overflow: "auto"}}/>
                    </Section>
                </Article>
            );
        default:
            return <Article className='text'></Article>;
    }
}