import { Button, Input, Select } from "antd";
import Article from "../BlockElement/Article/Article";
import Section from "../BlockElement/Section/Section";
import Heading from "../TextElement/Heading/Heading";
import { UnorderedListOutlined } from '@ant-design/icons';
import { ISite } from "../../types/ISite";
import './Site.scss';
import Container from "../BlockElement/Container/Container";
import { useState } from "react";

export default function Site({ site, setSite, sites, addSite, deleteSite, next }: ISite) {
    const [val, setVal] = useState('');

    const mappedSites = sites.map(element => { return {label: element.name, value: element.slug}; })

    return(
        <Article className='site'>
            <Section className='site__section'>
                <Heading type='h2'>Wybierz podstronę</Heading>
                <Select options={mappedSites} value={site} onChange={(value) => setSite(value)} style={{width: 300}}/>
                <Container>
                    {site && <Button htmlType='button' style={{ marginTop: 10 }} onClick={() => deleteSite(site)}>Usuń</Button>}
                    {site && <Button htmlType='button' type='primary' style={{ marginTop: 10, marginLeft: 20  }} onClick={() => next()}>Wybierz</Button>}
                </Container>
            </Section>
            <Section className='site__section'>
                <Heading type='h2'>Dodaj podstronę</Heading>
                <Input prefix={<UnorderedListOutlined />} style={{width: 300}} value={val} onChange={(e) => setVal(e.target.value)} />
                <Button style={{ marginTop: 10 }} onClick={() => { setVal(''); addSite(val);}}>Dodaj</Button>
            </Section>
        </Article>
    );
}